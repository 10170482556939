import { Box, Flex, Icon, Stack, Text } from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import CTA from './CTA'
import CTA2 from './CTA2'
import HeroBG from './HeroBG'
import { MdKeyboardArrowDown } from 'react-icons/md'

const Hero: React.FC = () => {
  const data = useStaticQuery(graphql`
    query HeroText {
      heroYaml {
        heroText
      }
    }
  `)

  return (
    <Box position="relative" h="calc(var(--vh, 1vh) * 100)">
      <HeroBG />
      <Flex
        top="0"
        left="0"
        mb="1rem"
        position="absolute"
        h="100%"
        w="100%"
        direction="column"
        wrap="nowrap"
        justify="flex-end"
        align="flex-start"
        minW="360px"
        zIndex="10"
        px={['1rem', '2rem', '2rem']}
      >
        <Box pb="3rem">
          <Text
            color="white"
            maxW="30ch"
            position="absolute"
            top="240"
            right="250"
            align-items="center"
            margin="0 auto"
            ml="1rem"
            fontWeight="semiBold"
            pb="2rem"
            pr="0.5rem"
            ta="center"
            fontSize={['2xl', '2xl', '4xl']}
            lineHeight="1.15"
            mb="1rem"
          >
              {data.heroYaml.heroText}
          </Text>
           <Flex direction="column" wrap="nowrap" maxW={['50%', '50%', '1280px']} mx="auto">
             <Flex flex="0 0 100%" direction={['column', 'column', 'row']} w="100%">
              <Box flex="0 0 100%" position="relative" mb="5%">
                <CTA2 />
              </Box>
              <Box>
                <CTA />
              </Box>
            </Flex>
              
            </Flex> 
        </Box>
        <Box
          as="a"
          href="#informacion"
          alignSelf="center"
          position="relative"
          sx={{
            '&::before, &::after': {
              content: '" "',
              position: 'absolute',
              bg: 'bg',
              top: '0',
              width: '40px',
              height: '100%',
            },
            '&::before': {
              left: '-20px',
              transform: 'skew(-30deg)',
              borderRadius: '25% 0 0 0',
            },
            '&::after': {
              right: '-20px',
              transform: 'skew(30deg)',
              borderRadius: '0 25% 0 0',
            },
          }}
        >
          <Stack
            direction="row"
            spacing="0.5rem"
            align="center"
            bg="bg"
            position="relative"
            zIndex="1"
            p="0.25rem"
          >
            <Icon color="text" as={MdKeyboardArrowDown} />
            <Text color="text" fontSize="xl">
              Más información
            </Text>
            <Icon color="text" as={MdKeyboardArrowDown} />
          </Stack>
        </Box>
      </Flex>
    </Box>
  )
}

export default Hero
